<script>
import { mapActions } from "vuex";
import { EventBus } from "@/event-bus/event-bus";

export default {
  name: "logout",
  mounted() {
    this.logout().then(() => {
      EventBus.$emit('page-loading', false);
      this.$router.push("login")
    });
  },
  methods: {
    ...mapActions(["logout"])
  },
  render() {
    return null;
  }
};
</script>
